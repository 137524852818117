import Vue from 'vue';
import _ from 'lodash';
import userStatuses from '@/constants/userStatuses';
import userMapper from '../mappers/userMapper';
import userService from '../services/userService';

const getDefaultState = () => ({
  user: _.cloneDeep(userMapper.defaultUser),
  password: '',
  users: [],
  isLoading: false,
  showUserEntry: false,
  userFilter: '',
});

const state = getDefaultState();

const getters = {
  user(state) {
    return state.user;
  },
  password(state) {
    return state.password;
  },
  users(state) {
    return state.users;
  },
  isLoading(state) {
    return state.isLoading;
  },
  userFilter(state) {
    return state.userFilter;
  },
  userById: (state) => (userId) => {
    const stateUser = state.users.find((user) => user.id === userId);
    if (stateUser) {
      return stateUser;
    }
    return undefined;
  },
  filteredUsers(state, getters) {
    return getters.users.filter((user) => (user.firstName && user.firstName.toLowerCase().includes(state.userFilter.toLowerCase()))
    || (user.lastName && user.lastName.toLowerCase().includes(state.userFilter.toLowerCase())));
  },
  showUserEntry(state) {
    return state.showUserEntry;
  },
};

const actions = {
  async getUsers({ commit }) {
    commit('setIsLoading', true);
    const apiUsers = await userService.getUsers();
    const users = userMapper.mapFromApiUsers(apiUsers);
    commit('addUsers', users);
    commit('setIsLoading', false);
  },
  async createUser({ dispatch }, request) {
    const mappedApiUser = userMapper.mapToApiUser(request.user);
    userService.createUser(mappedApiUser, request.password, request.enteredBy).then((createdUserId) => {
      mappedApiUser.id = createdUserId;
      dispatch('createdUser', mappedApiUser);
    });
  },
  createdUser({ commit }, user) {
    const mappedUser = userMapper.mapFromApiUser(user);
    commit('addUser', mappedUser);
  },
  async updateUser({ commit }, request) {
    const mappedApiUser = userMapper.mapToApiUser(request.user);
    userService.updateUser(mappedApiUser, request.password, request.enteredBy).then(() => {
      commit('editUser', request.user);
    });
  },
  updatedUser({ commit, dispatch, rootGetters }, apiUser) {
    const user = userMapper.mapFromApiUser(apiUser);
    const currentUser = rootGetters['account/user'];
    commit('editUser', user);
    if (user.id === currentUser.id) {
      if (user.status === userStatuses.INACTIVE) {
        dispatch('account/logout', null, { root: true });
        dispatch('alert/raiseError', 'The email or password is incorrect', { root: true });
      }
    }
  },
  resetState({ commit, dispatch }) {
    commit('resetState');
    dispatch('getUsers');
  },
  setUserFilter({ commit }, userFilter) {
    commit('setUserFilter', userFilter);
  },
  setUser({ commit }, user) {
    commit('setUser', user);
  },
  setUserFirstName({ commit }, firstName) {
    commit('setUserFirstName', firstName);
  },
  setUserLastName({ commit }, lastName) {
    commit('setUserLastName', lastName);
  },
  setUserEmail({ commit }, email) {
    commit('setUserEmail', email);
  },
  setUserPassword({ commit }, password) {
    commit('setUserPassword', password);
  },
  setUserAccessibleYards({ commit }, accessibleYards) {
    commit('setUserAccessibleYards', accessibleYards);
  },
  setUserDefaultYard({ commit }, defaultYard) {
    commit('setUserDefaultYard', defaultYard);
  },
  setUserRoles({ commit }, roles) {
    commit('setUserRoles', roles);
  },
  setUserStatus({ commit }, status) {
    commit('setUserStatus', status);
  },
  setShowUserEntry({ commit }, value) {
    commit('setShowUserEntry', value);
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserFirstName(state, firstName) {
    state.user.firstName = firstName;
  },
  setUserLastName(state, lastName) {
    state.user.lastName = lastName;
  },
  setUserEmail(state, email) {
    state.user.email = email;
  },
  setUserPassword(state, password) {
    state.password = password;
  },
  setUserAccessibleYards(state, accessibleYards) {
    state.user.accessibleYards = accessibleYards;
  },
  setUserDefaultYard(state, defaultYard) {
    state.user.defaultYard = defaultYard;
  },
  setUserRoles(state, roles) {
    state.user.roles = roles;
  },
  setUserStatus(state, status) {
    state.user.status = status;
  },
  addUsers(state, users) {
    state.users = users;
  },
  addUser(state, user) {
    const existingUser = state.users.find((actualUser) => actualUser.id === user.id);
    if (existingUser === undefined) {
      state.users.push(user);
    }
  },
  editUser(state, updatedUser) {
    const userIndex = state.users.findIndex((user) => user.id === updatedUser.id);

    if (userIndex >= 0) {
      Vue.set(state.users, userIndex, updatedUser);
    }
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setUserFilter(state, value) {
    state.userFilter = value;
  },
  setShowUserEntry(state, value) {
    state.showUserEntry = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
