<template>
  <div>
    <v-checkbox v-model="isReadyForPickup" label="Ready for Pickup" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({}),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    isReadyForPickup: {
      get() {
        if (this.selectedAppointment) {
          return this.selectedAppointment.isReadyForPickup;
        }
        return false;
      },
      set(isReadyForPickup) {
        this.setIsReadyForPickup(isReadyForPickup);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setIsReadyForPickup']),
  },
};
</script>
