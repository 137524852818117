<template>
  <v-card>
    <v-card-title>{{ isEditingCustomer ? 'Edit Customer' : 'Add New Customer' }}</v-card-title>
    <v-card-text>
      <v-text-field v-model="customerName" label="Customer Name"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn text @click="cancelButtonClicked">Cancel</v-btn>
      <v-btn color="primary" @click="saveButtonClicked">{{ isEditingCustomer ? 'Save Changes' : 'Add Customer' }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      customerName: '',
    };
  },
  computed: {
    ...mapGetters('customer', ['selectedCustomer', 'isEditingCustomer']),
  },
  methods: {
    ...mapActions('customer', [
      'addCustomer',
      'updateCustomer',
      'setShowCustomerEntry',
      'setSelectedCustomerName',
      'setIsEditingCustomer',
    ]),
    saveButtonClicked() {
      if (this.isEditingCustomer) {
        this.updateCustomer({ ...this.selectedCustomer, name: this.customerName });
      } else {
        this.addCustomer({ name: this.customerName });
      }
      this.setIsEditingCustomer(false);
      this.setShowCustomerEntry(false);
      this.setSelectedCustomerName(this.customerName);
      this.customerName = '';
    },
    cancelButtonClicked() {
      this.setIsEditingCustomer(false);
      this.setShowCustomerEntry(false);
      this.customerName = '';
    },
  },
  watch: {
    isEditingCustomer: {
      handler(newVal) {
        if (newVal && this.selectedCustomer) {
          this.customerName = this.selectedCustomer.name;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.v-card {
  padding: 1em;
}
</style>
