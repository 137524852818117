import Vue from 'vue';

function getCustomers() {
  return Vue.axios.get('customers').then((result) => result.data.map((c) => ({
    id: c.id,
    name: c.name,
    locationRanges: c.locationRanges,
  }))).catch(() => null);
}

function createCustomer(customer) {
  return Vue.axios.post('customers', customer).then((result) => result.data).catch(() => null);
}

function updateCustomer(customer) {
  const route = `customers/${customer.id}`;
  return Vue.axios.put(route, customer).then((result) => result.data).catch(() => null);
}

function deleteCustomer(customer) {
  const route = `customers/${customer.id}`;
  return Vue.axios.delete(route).then((result) => result.data).catch(() => null);
}

function addCustomerLocationRange(customerId, startLocation, endLocation) {
  const route = `customers/${customerId}/assignedLocationRanges`;
  const request = {
    start: startLocation,
    end: endLocation,
  };
  return Vue.axios.post(route, request).then((result) => result.data).catch(() => null);
}

function removeCustomerLocationRange(customerId, startLocation, endLocation) {
  const route = `customers/${customerId}/removeAssignedLocationRange`;
  const request = {
    start: startLocation,
    end: endLocation,
  };
  return Vue.axios.put(route, request).then((result) => result.data).catch(() => null);
}

export default {
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  addCustomerLocationRange,
  removeCustomerLocationRange,
};
