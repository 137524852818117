<template>
  <div>
    <h1>Appointment Upload</h1>
    <v-file-input
      label='Upload CSV or Excel File'
      accept='.csv,.xlsx'
      v-model='selectedFile'
    ></v-file-input>
    <v-btn
      v-bind:disabled="submitButtonDisabled"
      @click='uploadAppointments'>
      Upload Appointments
    </v-btn>
    <v-data-table
      v-if="addedAppointments.length > 0"
      :headers="outputTableHeaders"
      :items="addedAppointments">
      <template v-slot:item.date="{ item }">
        {{ (item.startDate.getMonth() + 1).toString().padStart(2, '0') }}/{{ item.startDate.getDate().toString().padStart(2, '0') }}
      </template>
      <template v-slot:item.startTime="{ item }">
        {{ item.startTime.hour.toString().padStart(2, '0') }}:{{ item.startTime.minute.toString().padStart(2, '0') }}
      </template>
      <template v-slot:item.endTime="{ item }">
        {{ item.endTime.hour.toString().padStart(2, '0') }}:{{ item.endTime.minute.toString().padStart(2, '0') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as XLSX from 'xlsx';

export default {
  data: () => ({
    selectedFile: null,
    addedAppointments: [],
    outputTableHeaders: [
      { text: 'PCL Contact', value: 'createdBy', align: 'start' },
      { text: 'Customer', value: 'customer.name' },
      { text: 'Load Id', value: 'loadId' },
      { text: 'Direction', value: 'direction' },
      { text: 'Load Type', value: 'actionType' },
      { text: 'Transport Type \n(FTL or LTL)', value: 'transportType' },
      { text: 'Carrier ID', value: 'equipment.carrier.name' },
      { text: 'Destination DC', value: 'referenceNumber' },
      { text: 'Date', value: 'date' },
      { text: 'Start Time', value: 'startTime' },
      { text: 'End Time', value: 'endTime' },
    ],
  }),
  methods: {
    ...mapActions('alert', ['raiseError']),
    ...mapActions('appointment', ['addAppointment']),
    async uploadAppointments() {
      try {
        let rows;
        if (this.selectedFile.name.endsWith('.csv')) {
          const fileText = await this.selectedFile.text();
          rows = fileText.split('\n').map((row) => row.split(','));
        } else if (this.selectedFile.name.endsWith('.xlsx')) {
          const fileData = await this.selectedFile.arrayBuffer();
          const workbook = XLSX.read(fileData, { type: 'array' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        } else {
          throw new Error('Unsupported file format. Please upload a .csv or .xlsx file.');
        }

        const headers = rows[0].map((header) => header.trim());
        const headerIndexes = {
          pclContact: headers.indexOf('PCL Contact'),
          customer: headers.indexOf('Customer'),
          direction: headers.indexOf('Appointment Gate Direction'),
          loadId: headers.indexOf('Load ID for Gate Access'),
          loadType: headers.indexOf('Load Type'),
          transPortType: headers.indexOf('Transport Type'),
          carrierId: headers.indexOf('Carrier ID'),
          destinationDC: headers.indexOf('Destination DC'),
          startTime: headers.indexOf('Start Date/Time (MM/DD/YYYY HH:MM)'),
        };

        if (Object.values(headerIndexes).includes(-1)) {
          throw new Error('Invalid file format. Please make sure the file has the correct headers.');
        }

        this.addedAppointments = [];
        const firstAppointmentRow = 2;
        for (let i = firstAppointmentRow; i < rows.length; i += 1) {
          const values = rows[i];
          const loadType = values[headerIndexes.loadType]?.trim().toUpperCase();
          if (loadType !== 'DROP' && loadType !== 'LIVE') {
            // eslint-disable-next-line no-continue
            continue;
          }

          const direction = values[headerIndexes.direction]?.trim().toUpperCase();
          if (direction !== 'IN' && direction !== 'OUT') {
            // eslint-disable-next-line no-continue
            continue;
          }

          const pclContact = values[headerIndexes.pclContact];
          const customer = values[headerIndexes.customer];
          const loadId = values[headerIndexes.loadId].toString();
          const transportType = values[headerIndexes.transPortType];
          const destinationDC = values[headerIndexes.destinationDC];
          let startDate;

          if (typeof values[headerIndexes.startTime] === 'number') {
            const dateCode = XLSX.SSF.parse_date_code(values[headerIndexes.startTime]);
            startDate = new Date(dateCode.y, dateCode.m - 1, dateCode.d, dateCode.H, dateCode.M);
          } else {
            startDate = new Date(values[headerIndexes.startTime]);
          }

          if (Number.isNaN(startDate.getTime())) {
            throw new Error(`Invalid date format: ${values[headerIndexes.startTime]}`);
          }

          const endDate = new Date(startDate.getTime() + 30 * 60 * 1000);

          const startTime = {
            hour: startDate.getHours(),
            minute: startDate.getMinutes(),
          };

          const endTime = {
            hour: endDate.getHours(),
            minute: endDate.getMinutes(),
          };

          const appointment = {
            customer: {
              name: customer,
            },
            equipment: {
              carrier: {
                name: values[headerIndexes.carrierId]?.trim(),
              },
            },
            createdBy: pclContact,
            referenceNumber: destinationDC,
            source: 'YMS - File Upload',
            loadId,
            startDate,
            endDate,
            startTime,
            endTime,
            transportType,
          };

          switch (loadType.toUpperCase()) {
            case 'DROP':
              appointment.actionType = 'Drop & Hook';
              break;
            case 'LIVE':
              appointment.actionType = 'Live';
              break;
            default:
              break;
          }

          switch (direction.toUpperCase()) {
            case 'IN':
              appointment.direction = 'In';
              break;
            case 'OUT':
              appointment.direction = 'Out';
              break;
            default:
              break;
          }

          // eslint-disable-next-line no-await-in-loop
          await this.addAppointment(appointment);
          this.addedAppointments.push(appointment);
        }
      } catch (error) {
        const errorMessage = `Error reading file: ${error.message}`;
        console.error(errorMessage);
        await this.raiseError(errorMessage);
      }

      this.selectedFile = null;
    },
  },
  computed: {
    submitButtonDisabled() {
      if (this.selectedFile) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
</style>
