<template>
  <div>
    <v-text-field class="pt-1 mt-0"
      v-model="loadId"
      v-bind:rules="requiredRule"
      label="Load Id *"
    ></v-text-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data: () => ({
    requiredRule: [rules.rules.required],
  }),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    loadId: {
      get() {
        if (this.selectedAppointment) {
          return this.selectedAppointment.loadId;
        }
        return '';
      },
      set(loadId) {
        this.setLoadId(loadId);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setLoadId']),
  },
};
</script>
