<template>
  <div>
    <h1>Customer Management</h1>
    <CustomerSelect />
    <CustomerManagementButtons />
    <v-dialog v-model="showCustomerEntry" max-width="600">
        <CustomerEntry />
    </v-dialog>
    <v-dialog v-model="showDeleteConfirmation" max-width="400">
        <CustomerDeleteConfirmation />
    </v-dialog>
    <CustomerLocations v-if="selectedCustomer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomerLocations from '@/components/customerLocations.vue';
import CustomerManagementButtons from '@/components/customerManagementButtons.vue';
import CustomerSelect from '@/components/customerSelect.vue';
import CustomerEntry from '@/components/customerEntry.vue';
import CustomerDeleteConfirmation from '@/components/customerDeleteConfirmation.vue';

export default {
  components: {
    CustomerLocations,
    CustomerManagementButtons,
    CustomerDeleteConfirmation,
    CustomerEntry,
    CustomerSelect,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('customer', [
      'selectedCustomer',
      'showCustomerEntry',
      'showDeleteConfirmation',
    ]),
  },
};
</script>

<style scoped>

</style>
