<template>
  <div>
    <v-text-field class="pt-1 mt-0"
      v-model="bol"
      label="BOL"
    ></v-text-field>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({}),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    bol: {
      get() {
        if (this.selectedAppointment) {
          return this.selectedAppointment.BOL;
        }
        return '';
      },
      set(bol) {
        this.setBOL(bol);
      },
    },
  },
  methods: {
    ...mapActions('appointment', ['setBOL']),
  },
};
</script>
