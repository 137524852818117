<template>
  <v-card>
    <v-card-title>Confirm Delete</v-card-title>
    <v-card-text>Are you sure you want to delete {{ selectedCustomerName }}?</v-card-text>
    <v-card-actions>
      <v-btn text @click="cancelButtonClicked">Cancel</v-btn>
      <v-btn color="error" @click="deleteButtonClicked">Delete</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  methods: {
    ...mapActions('customer', [
      'deleteCustomer',
      'setShowDeleteConfirmation',
    ]),
    cancelButtonClicked() {
      this.setShowDeleteConfirmation(false);
    },
    deleteButtonClicked() {
      this.setShowDeleteConfirmation(false);
      this.deleteCustomer(this.selectedCustomer);
    },
  },
  computed: {
    ...mapGetters('customer', [
      'selectedCustomer',
      'selectedCustomerName',
    ]),
  },
};
</script>

<style scoped>

</style>
