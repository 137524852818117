<template>
  <div>
    <AppointmentCsvUpload />
  </div>
</template>

<script>
import AppointmentCsvUpload from '@/components/appointmentImport.vue';

export default {
  components: {
    AppointmentCsvUpload,
  },
};
</script>

<style scoped>

</style>
