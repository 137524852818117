<template>
    <v-row>
      <v-col>
        <v-select
            class="pt-0"
            :items="referenceTypeOptions"
            v-model="referenceType"
            label="Reference Type"
          ></v-select>
      </v-col>
      <v-col>
        <v-text-field
            class="pt-0 pb-0"
            v-model="referenceValue"
            label="Reference Value"
          ></v-text-field>
      </v-col>
      <v-col>
        <v-btn
            class="pt-0 pb-0"
            color="primary"
            @click="addReferenceClicked"
            :disabled="referenceType == null || referenceValue == null"
        ><v-icon>mdi-plus</v-icon>Add</v-btn>
      </v-col>
      <v-col class="pt-0 pb-0">
          <v-chip-group class="pb-0">
            <v-chip :disabled="!hasEditPermissions" @click="removeReference('Delivery Order Number')" v-if="this.selectedAppointment.deliveryOrderNumber">
              Delivery Order Number: {{deliveryOrderNumber}}</v-chip>
            <v-chip :disabled="!hasEditPermissions" @click="removeReference('P.O. Number')" v-if="this.selectedAppointment.PONumber">
              PO Number: {{PONumber}}</v-chip>
            <v-chip :disabled="!hasEditPermissions" @click="removeReference('S.O. Number')" v-if="this.selectedAppointment.SONumber">
              SO Number: {{SONumber}}</v-chip>
            <v-chip :disabled="!hasEditPermissions" @click="removeReference('Brokerage Reference Number')" v-if="this.selectedAppointment.brokerageReferenceNumber">
              Brokerage Reference Number: {{brokerageReferenceNumber}}</v-chip>
            <v-chip :disabled="!hasEditPermissions" @click="removeReference('Reference Number')" v-if="this.selectedAppointment.referenceNumber">
              Reference Number: {{referenceNumber}}</v-chip>
            <v-chip :disabled="!hasEditPermissions" @click="removeReference('Pick Up Number')" v-if="this.selectedAppointment.pickupNumber">
              Pickup Number: {{pickupNumber}}</v-chip>
            <v-chip :disabled="!hasEditPermissions" @click="removeReference('TMS Reference Number')" v-if="this.selectedAppointment.TMSReferenceNumber">
              TMS Reference Number: {{TMSReferenceNumber}}</v-chip>
          </v-chip-group>
        </v-col>
    </v-row>
  </template>

<script>
import { mapActions, mapGetters } from 'vuex';
import referenceTypes from '../constants/referenceTypes';
import appointmentService from '../services/appointmentService';

export default {
  data: () => ({
    referenceType: null,
    referenceValue: null,
  }),
  computed: {
    ...mapGetters('appointment', ['selectedAppointment']),
    ...mapGetters('account', ['isAdmin', 'isDeveloper']),
    referenceTypeOptions() {
      return appointmentService.getReferenceTypeOptions();
    },
    referenceNumber: {
      get() {
        return this.selectedAppointment.referenceNumber;
      },
      set(val) {
        this.setReferenceNumber(val);
      },
    },
    BOL: {
      get() {
        return this.selectedAppointment.BOL;
      },
      set(val) {
        this.setBOL(val);
      },
    },
    brokerageReferenceNumber: {
      get() {
        return this.selectedAppointment.brokerageReferenceNumber;
      },
      set(val) {
        this.setBrokerageReferenceNumber(val);
      },
    },
    pickupNumber: {
      get() {
        return this.selectedAppointment.pickupNumber;
      },
      set(val) {
        this.setPickupNumber(val);
      },
    },
    deliveryOrderNumber: {
      get() {
        return this.selectedAppointment.deliveryOrderNumber;
      },
      set(val) {
        this.setDeliveryOrderNumber(val);
      },
    },
    PONumber: {
      get() {
        return this.selectedAppointment.PONumber;
      },
      set(val) {
        this.setPONumber(val);
      },
    },
    SONumber: {
      get() {
        return this.selectedAppointment.SONumber;
      },
      set(val) {
        this.setSONumber(val);
      },
    },
    TMSReferenceNumber: {
      get() {
        return this.selectedAppointment.TMSReferenceNumber;
      },
      set(val) {
        this.setTMSReferenceNumber(val);
      },
    },
    hasEditPermissions() {
      return this.isAdmin || this.isDeveloper;
    },
  },
  methods: {
    ...mapActions('appointment', [
      'setReferenceNumber',
      'setBOL',
      'setBrokerageReferenceNumber',
      'setPickupNumber',
      'setDeliveryOrderNumber',
      'setPONumber',
      'setSONumber',
      'setTMSReferenceNumber']),
    addReferenceClicked() {
      this.setAppointmentReference(this.referenceType, this.referenceValue);
      this.referenceType = null;
      this.referenceValue = null;
    },
    removeReference(reference) {
      if (this.hasEditPermissions) {
        this.setAppointmentReference(reference, '');
      }
    },
    setAppointmentReference(type, value) {
      switch (type) {
        case referenceTypes.REFERENCE_NUMBER:
          this.setReferenceNumber(value);
          break;
        case referenceTypes.BOL:
          this.setBOL(value);
          break;
        case referenceTypes.BROKERAGE_REFERENCE_NUMBER:
          this.setBrokerageReferenceNumber(value);
          break;
        case referenceTypes.PICK_UP_NUMBER:
          this.setPickupNumber(value);
          break;
        case referenceTypes.DELIVERY_ORDER_NUMBER:
          this.setDeliveryOrderNumber(value);
          break;
        case referenceTypes.PO_NUMBER:
          this.setPONumber(value);
          break;
        case referenceTypes.SO_NUMBER:
          this.setSONumber(value);
          break;
        case referenceTypes.TMS_REFERENCE_NUMBER:
          this.setTMSReferenceNumber(value);
          break;
        default:
      }
    },
  },
};
</script>
