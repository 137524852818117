import _ from 'lodash';
import userMapper from '@/mappers/userMapper';
import router from '../router/index';
import userService from '../services/userService';

const defaultUser = {
  email: '',
  accessibleYards: [],
  roles: [],
  defaultYard: {},
};

function initializeState() {
  const localStorageUser = JSON.parse(localStorage.getItem('user'));
  const localStorageSelectedYard = JSON.parse(localStorage.getItem('selectedYard'));
  if (localStorageUser) {
    return {
      isLoggedIn: true,
      user: localStorageUser,
      selectedYard: localStorageSelectedYard || localStorageUser.defaultYard,
    };
  }
  return {
    isLoggedIn: false,
    user: _.cloneDeep(defaultUser),
    selectedYard: {},
  };
}

const state = initializeState();

const getters = {
  fullName() {
    if (state.isLoggedIn) {
      return `${state.user.firstName} ${state.user.lastName}`;
    }

    return '';
  },
  user() {
    return state.user;
  },
  isDeveloper: (state) => state.user.roles.includes('Developer'),
  isAdmin: (state) => state.user.roles.includes('Admin'),
  isLoggedIn() {
    return state.isLoggedIn;
  },
  selectedYard() {
    // TODO: REMOVE START OF TEMP LOGIC #1
    if (state.selectedYard && state.selectedYard.id) {
      const tempSelectedYard = {
        yardId: state.selectedYard.id,
        name: state.selectedYard.name,
      };
      state.selectedYard = tempSelectedYard;
    }
    // TODO: REMOVE END OF TEMP LOGIC #1
    return state.selectedYard;
  },
  accessibleYards(state, getters, rootState, rootGetters) {
    const yards = rootGetters['yard/yards'];
    // TODO: REMOVE START OF TEMP LOGIC #2
    if (getters.user.accessibleYards) {
      return getters.user.accessibleYards;
    }
    // TODO: REMOVE END OF TEMP LOGIC #2
    return yards.filter((yards) => getters.user.accessibleYardIds.includes(yards.yardId));
  },
  defaultYard(state, getters, rootState, rootGetters) {
    const localStorageUser = JSON.parse(localStorage.getItem('user'));
    if (localStorageUser.defaultYard && localStorageUser.defaultYard.id) {
      return localStorageUser.defaultYard;
    }

    const userId = getters.user.id;
    const user = rootGetters['admin/userById'](userId);
    if (user.defaultYard && user.defaultYard.id) {
      localStorageUser.defaultYard = _.cloneDeep(user.defaultYard);
      localStorageUser.defaultYard = _.cloneDeep(user.defaultYard.id);
      localStorage.setItem('user', JSON.stringify(localStorageUser));

      return user.defaultYard;
    }

    const yards = rootGetters['yard/yards'];
    const defaultYard = yards.find((yard) => localStorageUser.defaultYardId === yard.yardId);
    localStorageUser.defaultYard = _.cloneDeep(defaultYard);
    localStorage.setItem('user', JSON.stringify(localStorageUser));

    return defaultYard;
  },
};

const actions = {
  async login({
    // eslint-disable-next-line no-unused-vars
    commit, dispatch, getters, rootGetters,
  }, { email, password }) {
    commit('setUserEmail', email);

    const logInResult = await userService.login({ email, password }).catch((error) => {
      console.log(error);
      commit('setIsLoggedIn', false);
      commit('setUser', _.cloneDeep(defaultUser));
      commit('setSelectedYard', { });
    });

    if (logInResult) {
      await dispatch('yard/getYards', null, { root: true });
      const yards = rootGetters['yard/yards'];
      yards.forEach((yard) => {
        if (yard.yardId === logInResult.user.defaultYardId) {
          commit('setUserDefaultYard', yard);
          dispatch('changeSelectedYard', yard, null);
        }
      });
      await dispatch('admin/getUsers', null, { root: true });
      await dispatch('location/getLocations', null, { root: true });
      const apiUser = logInResult.user;
      const user = userMapper.mapFromApiUser(apiUser);
      commit('setIsLoggedIn', true);
      commit('setUser', user);
      await router.push('/gate');
    }
  },
  logout({ commit }) {
    userService.logout();
    commit('setIsLoggedIn', false);
    commit('setUser', _.cloneDeep(defaultUser));
    commit('setSelectedYard', { });
    if (router.currentRoute.name.toLowerCase() !== 'login') {
      router.push('/login');
    }
  },
  changeSelectedYard({ commit }, selectedYard) {
    commit('setSelectedYard', selectedYard);
    localStorage.setItem('selectedYard', JSON.stringify(selectedYard));
  },
  // eslint-disable-next-line no-empty-pattern
  requestPasswordReset({}, payload) {
    return userService.requestPasswordReset(payload);
  },
  async resetPassword({ dispatch }, payload) {
    await userService.resetPassword(payload).then(() => {
      router.push('/login');
      dispatch('alert/raiseSuccess', 'Your password has been successfully changed!', { root: true });
    });
  },
  setUser({ commit }, user) {
    commit('setUser', user);
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserEmail(state, email) {
    state.user.email = email;
  },
  setUserDefaultYard(state, defaultYard) {
    state.user.defaultYard = defaultYard;
  },
  setSelectedYard(state, selectedYard) {
    state.selectedYard = selectedYard;
  },
  setIsLoggedIn(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
