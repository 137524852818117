<template>
  <v-card>
    <v-card-title>
      <v-container>
        <v-row>
          <v-col md="8">
            <span>Select a location to view available spots</span>
          </v-col>
          <v-col md="4">
            <v-select
              :items="this.availableGroups"
              v-model="selectedBlock"
              label="Location"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-progress-circular
        indeterminate
        size="100"
        v-if="isLoading"
        class="offset-md-5"
      ></v-progress-circular>
      <v-container fluid flex v-if="!isLoading">
        <v-row>
          <v-col
            v-for="location in this.availableLocations"
            :key="location.displayName"
            xs="1"
            cols="auto"
            class="pr-0"
          >
            <v-sheet
              :color="getLocationColor(location)"
              elevation="1"
              height="50"
              width="50"
              class="d-flex justify-center align-center"
              style="cursor: pointer"
              v-on:click="spotSelected(location)"
            >
              {{ location.displayName }}
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="selectedSpots.length !== 2" color="primary" text @click="confirmSelection"> Confirm </v-btn>
        <v-btn @click="closeButtonClicked" color="primary" text> Close </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      selectedSpots: [],
    };
  },
  computed: {
    selectedBlock: {
      get() {
        return this.selectedGroup;
      },
      set(value) {
        this.setSelectedGroup(value);
      },
    },
    availableLocations: {
      get() {
        return this.locationsByGroup(this.selectedBlock);
      },
    },
    availableGroups: {
      get() {
        return _.filter(this.groupNames, (groupName) => /^Block \d{3}$/.test(groupName));
      },
    },
    ...mapGetters('location', [
      'isLoading',
      'groupNames',
      'selectedGroup',
      'locationsByGroup',
    ]),
  },
  methods: {
    ...mapActions('location', ['setSelectedGroup']),
    ...mapActions('customer', ['setShowSpotRangeSelection', 'addLocationRange']),
    spotSelected(spot) {
      if (this.selectedSpots.length < 2 && !this.selectedSpots.includes(spot)) {
        this.selectedSpots.push(spot);
      } else if (this.selectedSpots.includes(spot)) {
        this.selectedSpots = this.selectedSpots.filter((s) => s.id !== spot.id);
      }
    },
    confirmSelection() {
      if (this.selectedSpots.length === 2) {
        const range = {
          start: this.selectedSpots[0],
          end: this.selectedSpots[1],
        };
        this.addLocationRange(range);
        this.setShowSpotRangeSelection(false);
        this.selectedSpots = [];
      }
    },
    closeButtonClicked() {
      this.setShowSpotRangeSelection(false);
    },
    getLocationColor(location) {
      if (this.selectedSpots.includes(location)) {
        return 'blue';
      }
      return 'white';
    },
  },
  mounted() {
    this.setSelectedGroup(this.groupNames[0]);
  },
};
</script>
