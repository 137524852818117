<template>
  <div class="button-group">
    <v-btn @click="clearSelectedCustomer">Clear Selection</v-btn>
    <v-btn color="primary" @click="addButtonClicked">Add New Customer</v-btn>
    <v-btn color="warning" :disabled="!selectedCustomer" @click="editButtonClicked">Edit Customer</v-btn>
    <v-btn color="error" :disabled="!selectedCustomer" @click="deleteButtonClicked">Delete Customer</v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  methods: {
    ...mapActions('customer', [
      'setSelectedCustomerName',
      'setShowCustomerEntry',
      'setIsEditingCustomer',
      'setShowDeleteConfirmation',
    ]),
    clearSelectedCustomer() {
      this.setSelectedCustomerName('');
    },
    addButtonClicked() {
      this.setShowCustomerEntry(true);
    },
    editButtonClicked() {
      this.setIsEditingCustomer(true);
      this.setShowCustomerEntry(true);
    },
    deleteButtonClicked() {
      this.setShowDeleteConfirmation(true);
    },
  },
  computed: {
    ...mapGetters('customer', [
      'selectedCustomer',
    ]),
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  gap: 1em;
  margin-bottom: 2em;
}
</style>
