import Vue from 'vue';
import customerService from '../services/customerService';

const state = {
  customers: [],
  selectedCustomerName: '',
  showSpotRangeSelection: false,
  showCustomerEntry: false,
  showDeleteConfirmation: false,
  isEditingCustomer: false,
};

const getters = {
  customers: (state) => state.customers,
  selectedCustomer: (state) => {
    for (let i = 0; i < state.customers.length; i += 1) {
      if (state.customers[i].name === state.selectedCustomerName) {
        return state.customers[i];
      }
    }

    return null;
  },
  selectedCustomerName: (state) => state.selectedCustomerName,
  selectedRanges: (state, getters) => getters.selectedCustomer.locationRanges,
  showSpotRangeSelection: (state) => state.showSpotRangeSelection,
  showCustomerEntry: (state) => state.showCustomerEntry,
  showDeleteConfirmation: (state) => state.showDeleteConfirmation,
  isEditingCustomer: (state) => state.isEditingCustomer,
};

const mutations = {
  setCustomers(state, customers) {
    state.customers = customers;
  },
  setSelectedCustomerName(state, id) {
    state.selectedCustomerName = id;
  },
  setShowSpotRangeSelection(state, shouldShow) {
    state.showSpotRangeSelection = shouldShow;
  },
  setShowCustomerEntry(state, shouldShow) {
    state.showCustomerEntry = shouldShow;
  },
  setShowDeleteConfirmation(state, shouldShow) {
    state.showDeleteConfirmation = shouldShow;
  },
  addLocationRange(state, range) {
    if (state.selectedCustomerName) {
      state.customers.forEach((c) => {
        if (c.name === state.selectedCustomerName) {
          c.locationRanges.push(range);
        }
      });
    }
  },
  removeLocationRange(state, rangeIndex) {
    if (state.selectedCustomerName) {
      state.customers.forEach((c) => {
        if (c.name === state.selectedCustomerName) {
          c.locationRanges = c.locationRanges.filter((_, i) => i !== rangeIndex);
        }
      });
    }
  },
  addCustomer(state, customer) {
    state.customers.push(customer);
  },
  updateCustomer(state, customer) {
    for (let i = 0; i < state.customers.length; i += 1) {
      if (state.customers[i].id === customer.id) {
        Vue.set(state.customers, i, customer);
      }
    }
  },
  deleteCustomer(state, customer) {
    const index = state.customers.findIndex((c) => c.name === customer.name);
    if (index !== -1) {
      state.customers.splice(index, 1);
    }
  },
  setIsEditingCustomer(state, isEditing) {
    state.isEditingCustomer = isEditing;
  },
};

const actions = {
  async setCustomers({ commit }) {
    const customers = await customerService.getCustomers();
    commit('setCustomers', customers);
  },
  setSelectedCustomerName({ commit }, customer) {
    commit('setSelectedCustomerName', customer);
  },
  async addLocationRange({ commit, getters }, range) {
    await customerService.addCustomerLocationRange(getters.selectedCustomer.id, range.start, range.end);
    commit('addLocationRange', range);
  },
  async removeLocationRange({ commit, getters }, rangeIndex) {
    const range = getters.selectedRanges[rangeIndex];
    await customerService.removeCustomerLocationRange(getters.selectedCustomer.id, range.start, range.end);
    commit('removeLocationRange', rangeIndex);
  },
  async addCustomer({ commit, dispatch }, customer) {
    try {
      const createdCustomer = await customerService.createCustomer(customer);
      commit('addCustomer', createdCustomer);
      dispatch('alert/raiseSuccess', 'Customer added successfully!', { root: true });
    } catch (error) {
      console.log(error);
      dispatch('alert/raiseError', 'Failed to create customer', { root: true });
    }
  },
  async updateCustomer({ commit, dispatch }, customer) {
    try {
      await customerService.updateCustomer(customer);
      commit('updateCustomer', customer);
      dispatch('alert/raiseSuccess', 'Customer updated successfully!', { root: true });
    } catch (error) {
      console.log(error);
      dispatch('alert/raiseError', 'Failed to update customer', { root: true });
    }
  },
  async deleteCustomer({ commit, dispatch }, customer) {
    try {
      await customerService.deleteCustomer(customer);
      commit('deleteCustomer', customer);
      dispatch('alert/raiseSuccess', 'Customer deleted successfully!', { root: true });
    } catch (error) {
      console.log(error);
      dispatch('alert/raiseError', 'Failed to delete customer', { root: true });
    }
  },
  setShowSpotRangeSelection({ commit }, shouldShow) {
    commit('setShowSpotRangeSelection', shouldShow);
  },
  setShowCustomerEntry({ commit }, shouldShow) {
    commit('setShowCustomerEntry', shouldShow);
  },
  setIsEditingCustomer({ commit }, isEditing) {
    commit('setIsEditingCustomer', isEditing);
  },
  setShowDeleteConfirmation({ commit }, shouldShow) {
    commit('setShowDeleteConfirmation', shouldShow);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
